let wasm;

function addToExternrefTable0(obj) {
    const idx = wasm.__externref_table_alloc();
    wasm.__wbindgen_export_2.set(idx, obj);
    return idx;
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        const idx = addToExternrefTable0(e);
        wasm.__wbindgen_exn_store(idx);
    }
}

const cachedTextDecoder = (typeof TextDecoder !== 'undefined' ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true }) : { decode: () => { throw Error('TextDecoder not available') } } );

if (typeof TextDecoder !== 'undefined') { cachedTextDecoder.decode(); };

let cachedUint8ArrayMemory0 = null;

function getUint8ArrayMemory0() {
    if (cachedUint8ArrayMemory0 === null || cachedUint8ArrayMemory0.byteLength === 0) {
        cachedUint8ArrayMemory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8ArrayMemory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8ArrayMemory0().subarray(ptr, ptr + len));
}

let WASM_VECTOR_LEN = 0;

const cachedTextEncoder = (typeof TextEncoder !== 'undefined' ? new TextEncoder('utf-8') : { encode: () => { throw Error('TextEncoder not available') } } );

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8ArrayMemory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8ArrayMemory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8ArrayMemory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let cachedDataViewMemory0 = null;

function getDataViewMemory0() {
    if (cachedDataViewMemory0 === null || cachedDataViewMemory0.buffer.detached === true || (cachedDataViewMemory0.buffer.detached === undefined && cachedDataViewMemory0.buffer !== wasm.memory.buffer)) {
        cachedDataViewMemory0 = new DataView(wasm.memory.buffer);
    }
    return cachedDataViewMemory0;
}

function takeFromExternrefTable0(idx) {
    const value = wasm.__wbindgen_export_2.get(idx);
    wasm.__externref_table_dealloc(idx);
    return value;
}

function getArrayJsValueFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    const mem = getDataViewMemory0();
    const result = [];
    for (let i = ptr; i < ptr + 4 * len; i += 4) {
        result.push(wasm.__wbindgen_export_2.get(mem.getUint32(i, true)));
    }
    wasm.__externref_drop_slice(ptr, len);
    return result;
}
/**
 * Escapes all regular expression meta characters in `text`.
 *
 * The string returned may be safely used as a literal in a regular
 * expression.
 * @param {string} text
 * @returns {string}
 */
export function escape(text) {
    let deferred2_0;
    let deferred2_1;
    try {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.escape(ptr0, len0);
        deferred2_0 = ret[0];
        deferred2_1 = ret[1];
        return getStringFromWasm0(ret[0], ret[1]);
    } finally {
        wasm.__wbindgen_free(deferred2_0, deferred2_1, 1);
    }
}

const RRegexFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rregex_free(ptr >>> 0, 1));
/**
 * A compiled regular expression for matching Unicode strings.
 *
 * A `RRegex` can be used to search haystacks, split haystacks into substrings
 * or replace substrings in a haystack with a different substring. All
 * searching is done with an implicit `(?s:.)*?` at the beginning and end of
 * an pattern. To force an expression to match the whole string (or a prefix
 * or a suffix), you must use an anchor like `^` or `$` (or `\A` and `\z`).
 *
 * While this library will handle Unicode strings (whether in the regular
 * expression or in the haystack), all positions returned are **byte
 * offsets**. Every byte offset is guaranteed to be at a Unicode code point
 * boundary. That is, all offsets returned by the `RRegex` API are guaranteed
 * to be ranges that can slice an `Uint8Array` created with a TextEncoder.
 *
 * # Example: slicing over UTF strings
 *
 * ```typescript
 * import { RRegex } from "rregex"
 *
 * const re = new RRegex("ä")
 * const m = re.find("äöü") // { start: 0, end: 2 }
 *
 * const buff = new TextEncoder().encode("äöü")
 * const slice = buff.slice(m.start, m.end)
 * expect(new TextDecoder().decode(slice)).toBe("ä")
 * ```
 *
 * # Example: find the offsets of a US phone number:
 *
 * ```typescript
 * import { RRegex } from "rregex"
 *
 * const re = new RRegex("[0-9]{3}-[0-9]{3}-[0-9]{4}");
 * const m = re.find("phone: 111-222-3333");
 * expect(m.start).toBe(7);
 * expect(m.end).toBe(19);
 * ```
 *
 * @see https://docs.rs/regex/latest/regex/
 */
export class RRegex {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RRegexFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rregex_free(ptr, 0);
    }
    /**
     * Compiles a regular expression. Once compiled, it can be used repeatedly
     * to search, split or replace text in a string.
     *
     * If an invalid expression is given, then an error is returned.
     * @param {string} re
     */
    constructor(re) {
        const ptr0 = passStringToWasm0(re, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_new(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        this.__wbg_ptr = ret[0] >>> 0;
        RRegexFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
     * Returns true if and only if there is a match for the regex in the
     * string given.
     *
     * It is recommended to use this method if all you need to do is test
     * a match, since the underlying matching engine may be able to do less
     * work.
     *
     * # Example
     *
     * Test if some text contains at least one word with exactly 13
     * Unicode word characters:
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const text = "I categorically deny having triskaidekaphobia."
     * expect(new RRegex("\\b\\w{13}\\b").is_match(text)).toBe(true)
     * ```
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.is_match
     * @param {string} text - The string against which to match the regular expression
     * @return {boolean}
     */
    isMatch(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_isMatch(this.__wbg_ptr, ptr0, len0);
        return ret !== 0;
    }
    /**
     * Returns the same as is_match, but starts the search at the given offset.
     * The significance of the starting point is that it takes the surrounding
     * context into consideration. For example, the `\A` anchor can only match
     * when `start == 0`.
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.is_match_at
     * @param {string} text - The string against which to match the regular expression
     * @param {number} start - Zero-based index at which to start matching
     * @return {boolean}
     */
    isMatchAt(text, start) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_isMatchAt(this.__wbg_ptr, ptr0, len0, start);
        return ret !== 0;
    }
    /**
     * Returns the start and end byte range of the leftmost-first match in
     * `text`. If no match exists, then `undefined` is returned.
     *
     * Note that this should only be used if you want to discover the position
     * of the match. Testing the existence of a match is faster if you use
     * `isMatch`.
     *
     * # Example
     *
     * Find the start and end location of the first word with exactly 13
     * Unicode word characters:
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const text = "I categorically deny having triskaidekaphobia."
     * const m = new RRegex("\\b\\w{13}\\b").find(text)
     * expect(m.start).toBe(2)
     * expect(m.end).toBe(15)
     * ```
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.find
     * @param {string} text - The string against which to match the regular expression
     * @return {Match}
     */
    find(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_find(this.__wbg_ptr, ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return takeFromExternrefTable0(ret[0]);
    }
    /**
     * Returns the same as find, but starts the search at the given
     * offset.
     *
     * The significance of the starting point is that it takes the surrounding
     * context into consideration. For example, the `\A` anchor can only
     * match when `start == 0`.
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.find_at
     * @param {string} text - The string against which to match the regular expression
     * @param {number} start - Zero-based index at which to start matching
     * @returns {Match}
     */
    findAt(text, start) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_findAt(this.__wbg_ptr, ptr0, len0, start);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return takeFromExternrefTable0(ret[0]);
    }
    /**
     * Returns an array for each successive non-overlapping match in `text``,
     * returning the start and end byte indices with respect to `text`.
     *
     * # Example
     *
     * Find the start and end location of every word with exactly 13 Unicode
     * word characters:
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const text = "Retroactively relinquishing remunerations is reprehensible."
     * const matches = new RRegex("\\b\\w{13}\\b").findAll(text)
     * ```
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.find_iter
     * @param {string} text - The string against which to match the regular expression
     * @returns {Match}
     */
    findAll(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_findAll(this.__wbg_ptr, ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return takeFromExternrefTable0(ret[0]);
    }
    /**
     * Returns a list of the capture names in this regex.
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.capture_names
     */
    captureNames() {
        const ret = wasm.rregex_captureNames(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * Returns the capture groups corresponding to the leftmost-first
     * match in `text`. Capture group `0` always corresponds to the entire
     * match. If no match is found, then `undefined` is returned.
     *
     * You should only use `captures` if you need access to the location of
     * capturing group matches. Otherwise, `find` is faster for discovering
     * the location of the overall match.
     *
     * # Examples
     *
     * Say you have some text with movie names and their release years,
     * like "'Citizen Kane' (1941)". It'd be nice if we could search for text
     * looking like that, while also extracting the movie name and its release
     * year separately.
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const re = new RRegex("'([^']+)'\\s+\\((\\d{4})\)")
     * const text = "Not my favorite movie: 'Citizen Kane' (1941)."
     * const caps = re.captures(text)
     * expect(caps.get[1].value).toBe("Citizen Kane")
     * expect(caps.get[2].value).toBe("1941")
     * expect(caps.get[0].value).toBe("'Citizen Kane' (1941)")
     * ```
     *
     * Note that the full match is at capture group `0`. Each subsequent
     * capture group is indexed by the order of its opening `(`.
     *
     * We can make this example a bit clearer by using *named* capture groups:
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const re = new RRegex("'(?P<title>[^']+)'\\s+\\((?P<year>\\d{4})\)")
     * const text = "Not my favorite movie: 'Citizen Kane' (1941)."
     * const caps = re.captures(text)
     * expect(caps.name["title"].value).toBe("Citizen Kane")
     * expect(caps.name["year"].value).toBe("1941")
     * expect(caps.get[0].value).toBe("'Citizen Kane' (1941)")
     * ```
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.captures
     * @param {string} text - The string against which to match the regular expression
     * @returns {Captures|undefined}
     */
    captures(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_captures(this.__wbg_ptr, ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return takeFromExternrefTable0(ret[0]);
    }
    /**
     * Returns a list with all the non-overlapping capture groups matched
     * in `text`. This is operationally the same as `findAll`, except it
     * returns information about capturing group matches.
     *
     * # Example
     *
     * We can use this to find all movie titles and their release years in
     * some text, where the movie is formatted like "'Title' (xxxx)":
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const re = new RRegex("'(?P<title>[^']+)'\\s+\\((?P<year>\\d{4})\\)")
     * const text = "'Citizen Kane' (1941), 'The Wizard of Oz' (1939), 'M' (1931)."
     * for caps of re.captures_iter(text) {
     *     console.log(
     *         "Movie:", caps.name["title"].value, ","
     *         "Released:", caps.name["year"].value
     *     );
     * }
     * // Output:
     * // Movie: Citizen Kane, Released: 1941
     * // Movie: The Wizard of Oz, Released: 1939
     * // Movie: M, Released: 1931
     * ```
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.captures_iter
     * @param {string} text - The string against which to match the regular expression
     * @returns {Captures[]}
     */
    capturesAll(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_capturesAll(this.__wbg_ptr, ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return takeFromExternrefTable0(ret[0]);
    }
    /**
     * Returns the number of captures.
     *
     * This includes all named and unnamed groups, including the implicit
     * unnamed group that is always present and corresponds to the entire match.
     *
     * Since the implicit unnamed group is always included in this length, the
     * length returned is guaranteed to be greater than zero.
     *
     * ## Example
     *
     * ```typescript
     *  import { RRegex } from "rregex"
     *
     *  const re1 = new RRegex("(?P<y>\\d{4})-(?P<m>\\d{2})-(?P<d>\\d{2})")
     *  expect(re1.capturesLength()).toEqual(4)
     *
     *  const re2 = new RRegex("foo")
     *  expect(re2.capturesLength()).toEqual(1)
     *
     *  const re3 = new RRegex("(foo)")
     *  expect(re3.capturesLength()).toEqual(2)
     *
     *  const re4 = new RRegex("(?<a>.(?<b>.))(.)(?:.)(?<c>.)")
     *  expect(re4.capturesLength()).toEqual(5)
     *
     *  const re5 = new RRegex("[a&&b]")
     *  expect(re5.capturesLength()).toEqual(1)
     * ```
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.captures_len
     * @returns {number}
     */
    capturesLength() {
        const ret = wasm.rregex_capturesLength(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
     * Replaces the leftmost-first match with the replacement provided.
     * The replacement can be a regular string (where `$N` and `$name` are
     * expanded to match capture groups) or a function that takes the matches'
     * `Captures` and returns the replaced string.
     *
     * If no match is found, then a copy of the string is returned unchanged.
     *
     * # Replacement string syntax
     *
     * All instances of `$name` in the replacement text is replaced with the
     * corresponding capture group `name`.
     *
     * `name` may be an integer corresponding to the index of the
     * capture group (counted by order of opening parenthesis where `0` is the
     * entire match) or it can be a name (consisting of letters, digits or
     * underscores) corresponding to a named capture group.
     *
     * If `name` isn't a valid capture group (whether the name doesn't exist
     * or isn't a valid index), then it is replaced with the empty string.
     *
     * The longest possible name is used. e.g., `$1a` looks up the capture
     * group named `1a` and not the capture group at index `1`. To exert more
     * precise control over the name, use braces, e.g., `${1}a`.
     *
     * To write a literal `$` use `$$`.
     *
     * # Examples
     *
     * Note that this function is polymorphic with respect to the replacement.
     * In typical usage, this can just be a normal string:
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const re = new RRegex("[^01]+")
     * expect(re.replace("1078910", "").toBe("1010")
     * ```
     *
     * Here's the example using this expansion technique with named capture
     * groups:
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const re = new RRegex("(?P<last>[^,\\s]+),\\s+(?P<first>\\S+)")
     * const result = re.replace("Springsteen, Bruce", "$first $last")
     * expect(result).toBe("Bruce Springsteen")
     * ```
     *
     * Note that using `$2` instead of `$first` or `$1` instead of `$last`
     * would produce the same result. To write a literal `$` use `$$`.
     *
     * Sometimes the replacement string requires use of curly braces to
     * delineate a capture group replacement and surrounding literal text.
     * For example, if we wanted to join two words together with an
     * underscore:
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const re = new RRegex("(?P<first>\\w+)\\s+(?P<second>\\w+)")
     * const result = re.replace("deep fried", "${first}_$second")
     * expect(result).toBe("deep_fried")
     * ```
     *
     * Without the curly braces, the capture group name `first_` would be
     * used, and since it doesn't exist, it would be replaced with the empty
     * string.
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.replace
     * @param {string} text - The string against which to match the regular expression
     * @param {string} rep - It's a string, it will replace the substring matched by `pattern`.
     * @returns {string}
     */
    replace(text, rep) {
        let deferred3_0;
        let deferred3_1;
        try {
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            const ptr1 = passStringToWasm0(rep, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len1 = WASM_VECTOR_LEN;
            const ret = wasm.rregex_replace(this.__wbg_ptr, ptr0, len0, ptr1, len1);
            deferred3_0 = ret[0];
            deferred3_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred3_0, deferred3_1, 1);
        }
    }
    /**
     * Replaces at most `limit` non-overlapping matches in `text` with the
     * replacement provided. If `limit` is 0, then all non-overlapping matches
     * are replaced.
     *
     * See the documentation for `replace` for details on how to access
     * capturing group matches in the replacement string.
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.replacen
     * @param {string} text - The string against which to match the regular expression
     * @param {number} limit - Max number of replacement
     * @param {string} rep - It's a string, it will replace the substring matched by `pattern`.
     * @returns {string}
     */
    replacen(text, limit, rep) {
        let deferred3_0;
        let deferred3_1;
        try {
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            const ptr1 = passStringToWasm0(rep, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len1 = WASM_VECTOR_LEN;
            const ret = wasm.rregex_replacen(this.__wbg_ptr, ptr0, len0, limit, ptr1, len1);
            deferred3_0 = ret[0];
            deferred3_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred3_0, deferred3_1, 1);
        }
    }
    /**
     * Replaces all non-overlapping matches in `text` with the replacement
     * provided. This is the same as calling `replacen` with `limit` set to
     * `0`.
     *
     * See the documentation for `replace` for details on how to access
     * capturing group matches in the replacement string.
     *
     * See the documentation for `replace` for details on how to access capturing group matches in the replacement string.
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.replace_all
     * @param {string} text - The string against which to match the regular expression
     * @param {string} rep - It's a string, it will replace the substring matched by `pattern`.
     * @returns {string}
     */
    replaceAll(text, rep) {
        let deferred3_0;
        let deferred3_1;
        try {
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            const ptr1 = passStringToWasm0(rep, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len1 = WASM_VECTOR_LEN;
            const ret = wasm.rregex_replaceAll(this.__wbg_ptr, ptr0, len0, ptr1, len1);
            deferred3_0 = ret[0];
            deferred3_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred3_0, deferred3_1, 1);
        }
    }
    /**
     * Returns an iterator of substrings of `text` delimited by a match of the
     * regular expression. Namely, each element of the iterator corresponds to
     * text that *isn't* matched by the regular expression.
     *
     * This method will *not* copy the text given.
     *
     * # Example
     *
     * To split a string delimited by arbitrary amounts of spaces or tabs:
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const re = new RRegex(r"[ \\t]+")
     * const fields = re.split("a b \t  c\td    e")
     * expect(fields).toEqual(["a", "b", "c", "d", "e"])
     * ```
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.split
     * @param {string} text - The string against which to match the regular expression
     * @returns {string[]}
     */
    split(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_split(this.__wbg_ptr, ptr0, len0);
        var v2 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v2;
    }
    /**
     * Returns an iterator of at most `limit` substrings of `text` delimited
     * by a match of the regular expression. (A `limit` of `0` will return no
     * substrings.) Namely, each element of the iterator corresponds to text
     * that *isn't* matched by the regular expression. The remainder of the
     * string that is not split will be the last element in the iterator.
     *
     * This method will *not* copy the text given.
     *
     * # Example
     *
     * Get the first two words in some text:
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const re = new RRegex(r"\\W+")
     * const fields = re.splitn("Hey! How are you?", 3)
     * expect(fields).toEqual(["Hey", "How", "are you?"])
     * ```
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.splitn
     * @param {string} text - The string against which to match the regular expression
     * @param {number} limit - Max number result elements
     * @returns {string[]}
     */
    splitn(text, limit) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_splitn(this.__wbg_ptr, ptr0, len0, limit);
        var v2 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v2;
    }
    /**
     * Returns the end location of a match in the text given.
     *
     * This method may have the same performance characteristics as
     * `isMatch`, except it provides an end location for a match. In
     * particular, the location returned *may be shorter* than the proper end
     * of the leftmost-first match that you would find via `Regex.find`.
     *
     * Note that it is not guaranteed that this routine finds the shortest or
     * "earliest" possible match. Instead, the main idea of this API is that
     * it returns the offset at the point at which the internal regex engine
     * has determined that a match has occurred. This may vary depending on
     * which internal regex engine is used, and thus, the offset itself may
     * change.
     *
     * # Example
     *
     * Typically, `a+` would match the entire first sequence of `a` in some
     * text, but `shortest_match` can give up as soon as it sees the first
     * `a`.
     *
     * ```typescript
     * import { RRegex } from "rregex"
     *
     * const text = "aaaaa"
     * const = new RRegex("a+").shortest_match(text)
     * expect(pos).toBe(1)
     * ```
     *
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.shortest_match
     * @param {string} text - The string against which to match the regular expression
     * @returns {number|undefined}
     */
    shortestMatch(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_shortestMatch(this.__wbg_ptr, ptr0, len0);
        return ret === 0x100000001 ? undefined : ret;
    }
    /**
     * Returns the same as `shortest_match`, but starts the search at the
     * given offset.
     *
     * The significance of the starting point is that it takes the surrounding
     * context into consideration. For example, the `\A` anchor can only match
     * when `start == 0`.
     * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.shortest_match_at
     * @param {string} text - The string against which to match the regular expression
     * @param {number} start - Zero-based index at which to start matching
     * @returns {number|undefined}
     */
    shortestMatchAt(text, start) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_shortestMatchAt(this.__wbg_ptr, ptr0, len0, start);
        return ret === 0x100000001 ? undefined : ret;
    }
    /**
     * Returns the regular expression into a high level intermediate
     * representation.
     * @returns {any}
     */
    syntax() {
        const ret = wasm.rregex_syntax(this.__wbg_ptr);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return takeFromExternrefTable0(ret[0]);
    }
    /**
     * Returns a string representing the regular expression
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.rregex_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const RRegexSetFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rregexset_free(ptr >>> 0, 1));
/**
 * Match multiple (possibly overlapping) regular expressions in a single scan.
 * A regex set corresponds to the union of two or more regular expressions.
 *
 * That is, a regex set will match text where at least one of its constituent
 * regular expressions matches. A regex set as its formulated here provides a
 * touch more power: it will also report which regular expressions in the set match.
 * Indeed, this is the key difference between regex sets and a single `Regex`
 * with many alternates, since only one alternate can match at a time.
 *
 * @see https://docs.rs/regex/latest/regex/struct.RegexSet.html#method.empty
 */
export class RRegexSet {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RRegexSetFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rregexset_free(ptr, 0);
    }
    /**
     * Create a new regex set with the given regular expressions.
     *
     * This takes an of strings, if any item in the list is not a valid regular
     * expressions, then an error is returned.
     *
     * # Example
     *
     * Create a new regex set from an iterator of strings:
     *
     * ```typescript
     * import { RegexSet } from "rregex"
     *
     * const set = new RegexSet(["\\w+", "\\d+"])
     * expect(set.is_match("foo")).toBe(true)
     * ```
     * @param {Array<any>} list
     */
    constructor(list) {
        const ret = wasm.rregexset_new(list);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        this.__wbg_ptr = ret[0] >>> 0;
        RRegexSetFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
     * Returns true if and only if one of the regexes in this set matches
     * the text given.
     *
     * This method should be preferred if you only need to test whether any
     * of the regexes in the set should match, but don't care about *which*
     * regexes matched. This is because the underlying matching engine will
     * quit immediately after seeing the first match instead of continuing to
     * find all matches.
     *
     * Note that as with searches using `Regex`, the expression is unanchored
     * by default. That is, if the regex does not start with `^` or `\A`, or
     * end with `$` or `\z`, then it is permitted to match anywhere in the
     * text.
     *
     * # Example
     *
     * Tests whether a set matches some text:
     *
     * ```typescript
     * import { RegexSet } from "rregex"
     *
     * const set = new RegexSet(["\\w+", "\\d+"])
     * expect(set.is_match("foo")).toBe(true)
     * expect(!set.is_match("☃")).toBe(false)
     * ```
     *
     * @see https://docs.rs/regex/latest/regex/struct.RegexSet.html#method.is_match
     * @param {string} text - The string against which to match the regular expression
     * @return {boolean}
     */
    isMatch(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregexset_isMatch(this.__wbg_ptr, ptr0, len0);
        return ret !== 0;
    }
    /**
     * Returns the set of regular expressions that match in the given text.
     *
     * The set returned contains the index of each regular expression that
     * matches in the given text. The index is in correspondence with the
     * order of regular expressions given to `RegexSet`'s constructor.
     *
     * The set can also be used to iterate over the matched indices.
     *
     * Note that as with searches using `Regex`, the expression is unanchored
     * by default. That is, if the regex does not start with `^` or `\A`, or
     * end with `$` or `\z`, then it is permitted to match anywhere in the
     * text.
     *
     * # Example
     *
     * Tests which regular expressions match the given text:
     *
     * ```typescript
     * import { RegexSet } from "rregex"
     *
     * const set = new RegexSet([
     *     "\\w+",
     *     "\\d+",
     *     "\\pL+",
     *     "foo",
     *     "bar",
     *     "barfoo",
     *     "foobar",
     * ])
     * const matches = set.matches("foobar")
     * expect(matches).toBe([0, 2, 3, 4, 6])
     * ```
     *
     * @see https://docs.rs/regex/latest/regex/struct.RegexSet.html#method.matches
     * @param {string} text - The string against which to match the regular expression
     * @return {number[]}
     */
    matches(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregexset_matches(this.__wbg_ptr, ptr0, len0);
        var v2 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v2;
    }
}

async function __wbg_load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve Wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function __wbg_get_imports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbg_buffer_61b7ce01341d7f88 = function(arg0) {
        const ret = arg0.buffer;
        return ret;
    };
    imports.wbg.__wbg_fromCodePoint_ef89154c4db48bdf = function() { return handleError(function (arg0) {
        const ret = String.fromCodePoint(arg0 >>> 0);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_from_d68eaa96dba25449 = function(arg0) {
        const ret = Array.from(arg0);
        return ret;
    };
    imports.wbg.__wbg_get_9aa3dff3f0266054 = function(arg0, arg1) {
        const ret = arg0[arg1 >>> 0];
        return ret;
    };
    imports.wbg.__wbg_length_d65cf0786bfc5739 = function(arg0) {
        const ret = arg0.length;
        return ret;
    };
    imports.wbg.__wbg_new_254fa9eac11932ae = function() {
        const ret = new Array();
        return ret;
    };
    imports.wbg.__wbg_new_3ff5b33b1ce712df = function(arg0) {
        const ret = new Uint8Array(arg0);
        return ret;
    };
    imports.wbg.__wbg_new_688846f374351c92 = function() {
        const ret = new Object();
        return ret;
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_ba35896968751d91 = function(arg0, arg1, arg2) {
        const ret = new Uint8Array(arg0, arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_push_6edad0df4b546b2c = function(arg0, arg1) {
        const ret = arg0.push(arg1);
        return ret;
    };
    imports.wbg.__wbg_set_1d80752d0d5f0b21 = function(arg0, arg1, arg2) {
        arg0[arg1 >>> 0] = arg2;
    };
    imports.wbg.__wbg_set_3f1d0b984ed272ed = function(arg0, arg1, arg2) {
        arg0[arg1] = arg2;
    };
    imports.wbg.__wbg_set_4e647025551483bd = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = Reflect.set(arg0, arg1, arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbindgen_bigint_from_u64 = function(arg0) {
        const ret = BigInt.asUintN(64, arg0);
        return ret;
    };
    imports.wbg.__wbindgen_error_new = function(arg0, arg1) {
        const ret = new Error(getStringFromWasm0(arg0, arg1));
        return ret;
    };
    imports.wbg.__wbindgen_init_externref_table = function() {
        const table = wasm.__wbindgen_export_2;
        const offset = table.grow(4);
        table.set(0, undefined);
        table.set(offset + 0, undefined);
        table.set(offset + 1, null);
        table.set(offset + 2, true);
        table.set(offset + 3, false);
        ;
    };
    imports.wbg.__wbindgen_memory = function() {
        const ret = wasm.memory;
        return ret;
    };
    imports.wbg.__wbindgen_number_new = function(arg0) {
        const ret = arg0;
        return ret;
    };
    imports.wbg.__wbindgen_string_get = function(arg0, arg1) {
        const obj = arg1;
        const ret = typeof(obj) === 'string' ? obj : undefined;
        var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbindgen_string_new = function(arg0, arg1) {
        const ret = getStringFromWasm0(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };

    return imports;
}

function __wbg_init_memory(imports, memory) {

}

function __wbg_finalize_init(instance, module) {
    wasm = instance.exports;
    __wbg_init.__wbindgen_wasm_module = module;
    cachedDataViewMemory0 = null;
    cachedUint8ArrayMemory0 = null;


    wasm.__wbindgen_start();
    return wasm;
}

function initSync(module) {
    if (wasm !== undefined) return wasm;


    if (typeof module !== 'undefined') {
        if (Object.getPrototypeOf(module) === Object.prototype) {
            ({module} = module)
        } else {
            console.warn('using deprecated parameters for `initSync()`; pass a single object instead')
        }
    }

    const imports = __wbg_get_imports();

    __wbg_init_memory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return __wbg_finalize_init(instance, module);
}

async function __wbg_init(module_or_path) {
    if (wasm !== undefined) return wasm;


    if (typeof module_or_path !== 'undefined') {
        if (Object.getPrototypeOf(module_or_path) === Object.prototype) {
            ({module_or_path} = module_or_path)
        } else {
            console.warn('using deprecated parameters for the initialization function; pass a single object instead')
        }
    }

    if (typeof module_or_path === 'undefined') {
        module_or_path = new URL('rregex.wasm', import.meta.url);
    }
    const imports = __wbg_get_imports();

    if (typeof module_or_path === 'string' || (typeof Request === 'function' && module_or_path instanceof Request) || (typeof URL === 'function' && module_or_path instanceof URL)) {
        module_or_path = fetch(module_or_path);
    }

    __wbg_init_memory(imports);

    const { instance, module } = await __wbg_load(await module_or_path, imports);

    return __wbg_finalize_init(instance, module);
}

export { initSync };
export default __wbg_init;

/** Build metadata */
export const metadata = {
  "name": "rregex",
  "description": "Rust Regex binding for Javascript",
  "authors": [
    "Fede Ramirez <i@2fd.me>"
  ],
  "homepage": "http://rregex.dev",
  "repository": "https://github.com/2fd/rregex",
  "regex": "1.11.1",
  "regex-syntax": "0.8.5"
}
